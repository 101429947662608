html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}

.svgContainter {
  display: flex;
  justify-content: space-evenly ;
  align-items: center;
}

::-webkit-scrollbar {
  width: 0.5rem;
}
::-webkit-scrollbar-thumb {
  background-color: #3178FF;
  border-radius: 5px;
}
::-webkit-scrollbar-track {
  background-color: #101010;
}

body {
  margin: 0%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.margin {
  margin-left: 1rem;
  margin-right: 1rem;
}
@media (min-width: 1200px) {
  .margin {
    margin-left: 5rem;
    margin-right: 5rem;
  }
}

.padding {
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 1200px) {
  .padding {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

.subheading {
  width: 100%;
}
@media (min-width: 1200px) {
  .subheading {
    width: 55%;
    margin: 0 auto;
    display: inline-block;
  }
}

.light {
  z-index: 0;
  position: absolute;
  pointer-events: none;
}

.scrolled {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  backdrop-filter: blur(1rem);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
  transition: 600;
}